import React, { useEffect, useState } from "react";
import { ReactComponent as Loading } from "../util/assets/loading.svg";

const Spinner = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((r) => r + 30);
    }, 75);
    return () => clearInterval(interval);
  }, [75]);

  return (
    <Loading
      style={{
        transform: `rotate(${rotation}deg)`,
        fill: "blue",
        width: "75px",
        height: "75px",
      }}
    />
  );
};

export default Spinner;

import "./style/index.scss";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { Button, Card, Col, Form, Row, Space, Image, Collapse, Tooltip } from "antd";
import raioxIcon from "../../util/assets/icons/raiox.svg";
import ressoIcon from "../../util/assets/icons/ressonancia.svg";
import ecoIcon from "../../util/assets/icons/ecocardiograma.svg";
import ultraIcon from "../../util/assets/icons/ultrassonografia.svg";
import mamoIcon from "../../util/assets/icons/mamografia.svg";
import densiIcon from "../../util/assets/icons/densitometria.svg";
import geralIcon from "../../util/assets/icons/geral.svg";
import { FileDoneOutlined, PictureOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import { ResultadoState, setPage } from "../../redux/resultado/resultadoSlice";
import PdfView from "../pdf";
import moment from "moment";
import * as Icon from "phosphor-react";
import { ConfigState } from "../../redux/config/configSlice";
import { AtendimentoModel, ExameModel } from "../../models/resultado/atendimentoModel";

const { Paragraph } = Typography;
const { Panel } = Collapse;

type CardExamesProps = {
  exame: ExameModel;
};

const Resultados = () => {
  //estados globais
  const configState: ConfigState = useAppSelector((state) => state.config);
  const resultadoState: ResultadoState = useAppSelector((state) => state.resultado);

  //hooks
  const dispatch = useAppDispatch();

  //estados locais
  const [pdfBase64, setPdfBase64] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createPDF = async (data: any) => {
    setPdfBase64(data);
    dispatch(setPage(true));
  };

  const temLaudoAndamento = resultadoState.entity.exames.some((a) => !a.disponivelParaEntrega);

  const temLaudoDisponivel = resultadoState.entity.exames.some((a) => a.disponivelParaEntrega);

  const IconImageSelector = (props: any) => {
    switch (props.setor) {
      case "usg":
        return <Image width={40} height={40} src={ultraIcon} preview={false} />;
      case "mamo":
        return <Image width={40} height={40} src={mamoIcon} preview={false} />;
      case "eco":
        return <Image width={40} height={40} src={ecoIcon} preview={false} />;
      case "rm":
        return <Image width={40} height={40} src={ressoIcon} preview={false} />;
      case "tc":
        return <Image width={40} height={40} src={ressoIcon} preview={false} />;
      case "rx":
        return <Image width={40} height={40} src={raioxIcon} preview={false} />;
      case "do":
        return <Image width={40} height={40} src={densiIcon} preview={false} />;
      case "cint":
        return <Image width={40} height={40} src={ressoIcon} preview={false} />;
      default:
        return <Image width={40} height={40} src={geralIcon} preview={false} />;
    }
  };

  const CardExames = ({ exame }: CardExamesProps) => {
    return (
      <>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card key={exame.acessionNumber}>
              <Form layout="vertical">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item>
                      <Space direction="horizontal">
                        <IconImageSelector setor={exame.setor} />
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "12px",
                            color: configState.config.corPrimaria,
                          }}
                        >
                          {exame.nomeExame}
                        </span>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item>
                      <div className="descricao">
                        <span className="spanTextBold">MÉDICO SOLICITANTE:</span>
                        <span className="spanText">DR(A).{exame.solicitante.toUpperCase()}</span>
                        {exame.crmSolicitante !== null ? (
                          <>
                            <span className="spanText">CRM {exame.crmSolicitante.toUpperCase()}</span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    {exame.disponivelParaEntrega ? (
                      <>
                        {exame.exibirBotaoLaudo ? (
                          <Tooltip title={exame.mensagemBotaoLaudo}>
                            <Button
                              icon={<FileDoneOutlined />}
                              disabled={!exame.habilitarBotaoLaudo}
                              onClick={() => createPDF(exame.laudoPDF)}
                              style={{
                                backgroundColor: configState.config.corPrimaria,
                                color: "#ffffff",
                                marginRight: 5,
                                borderRadius: "5px",
                              }}
                            >
                              LAUDO
                            </Button>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                        {exame.urlDisponivel ? (
                          <>
                            <Button
                              icon={<PictureOutlined />}
                              onClick={() => exame.urlExame && window.open(exame.urlExame, "_blank")}
                              style={{
                                backgroundColor: configState.config.corSecundaria,
                                color: "#ffffff",
                                borderRadius: "5px",
                              }}
                            >
                              IMAGEM
                            </Button>
                          </>
                        ) : exame.laudoPossuiImagemPDF ? (
                          <>
                            <Button
                              icon={<PictureOutlined />}
                              onClick={() => createPDF(exame.laudoImagemPDF)}
                              style={{
                                backgroundColor: configState.config.corSecundaria,
                                color: "#ffffff",
                                borderRadius: "5px",
                              }}
                            >
                              IMAGEM
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        {exame.urlDisponivel ? (
                          <>
                            <Button
                              icon={<PictureOutlined />}
                              onClick={() => exame.urlExame && window.open(exame.urlExame, "_blank")}
                              style={{
                                backgroundColor: configState.config.corSecundaria,
                                color: "#ffffff",
                                borderRadius: "5px",
                              }}
                            >
                              IMAGEM
                            </Button>
                          </>
                        ) : exame.laudoPossuiImagemPDF ? (
                          <>
                            <Button
                              icon={<PictureOutlined />}
                              onClick={() => createPDF(exame.laudoImagemPDF)}
                              style={{
                                backgroundColor: configState.config.corSecundaria,
                                color: "#ffffff",
                                borderRadius: "5px",
                              }}
                            >
                              IMAGEM
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {resultadoState.page ? (
        <>
          <PdfView pdf={pdfBase64} />
        </>
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card key="1" title={"Olá, " + resultadoState.entity.nomePaciente.toUpperCase().split(" ")[0]} size="small" className="titleCard">
                <Paragraph>
                  Confira abaixo os resultados dos seus exames
                  <br />
                  realizados em {moment(resultadoState.entity.dataAtendimento).format("DD/MM/YYYY")}
                  .<br />
                  Unidade: {resultadoState.entity.unidade}, {resultadoState.entity.enderecoUnidade}.
                </Paragraph>
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              {temLaudoDisponivel ? (
                <>
                  <Collapse defaultActiveKey={["1"]}>
                    <Panel
                      header={
                        <>
                          <span>LAUDO DISPONIVEL</span>
                          <Icon.Circle size={16} color="#1BD04E" weight="fill" />
                        </>
                      }
                      key="1"
                    >
                      {resultadoState.entity.exames.map((a) => (
                        <CardExames exame={a} />
                      ))}
                    </Panel>
                  </Collapse>
                </>
              ) : temLaudoAndamento ? (
                <>
                  <Collapse defaultActiveKey={["2"]}>
                    <Panel
                      header={
                        <>
                          <span>LAUDO EM ANDAMENTO</span>
                          <Icon.Circle size={16} color="#F8C719" weight="fill" />
                        </>
                      }
                      key="2"
                    >
                      {resultadoState.entity.exames.map((a) => (
                        <CardExames exame={a} />
                      ))}
                    </Panel>
                  </Collapse>
                </>
              ) : temLaudoAndamento && temLaudoDisponivel ? (
                <>
                  <Collapse defaultActiveKey={["1", "2"]}>
                    <Panel
                      header={
                        <>
                          <span>LAUDO DISPONIVEL</span>
                          <Icon.Circle size={16} color="#1BD04E" weight="fill" />
                        </>
                      }
                      key="1"
                    >
                      {resultadoState.entity.exames.map((a) => (
                        <CardExames exame={a} />
                      ))}
                    </Panel>
                    <Panel
                      header={
                        <>
                          <span>LAUDO EM ANDAMENTO</span>
                          <Icon.Circle size={16} color="#F8C719" weight="fill" />
                        </>
                      }
                      key="2"
                    >
                      {resultadoState.entity.exames.map((a) => (
                        <CardExames exame={a} />
                      ))}
                    </Panel>
                  </Collapse>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Resultados;

import api from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const listarConfiguracoes = createAsyncThunk("/Config/ListarConfiguracoes", async (empresa: string) => {
  try {
    const data = await api.get("/Config/ListarConfiguracoes/" + empresa);
    return data.data.result;
  } catch (error: any) {
    throw new Error(error.response.data.Message);
  }
});

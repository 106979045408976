import { createSlice } from "@reduxjs/toolkit";
import { ConfigModel } from "../../models/config/configModel";
import { listarConfiguracoes } from "../../services/configService";

export interface ConfigState {
  loading: boolean;
  config: ConfigModel;
  page: string;
}

const initialState: ConfigState = {
  loading: true,
  config: {} as ConfigModel,
  page: "acessar",
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    //listar config
    builder.addCase(listarConfiguracoes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listarConfiguracoes.fulfilled, (state, action) => {
      state.config = action.payload;
      state.loading = false;
    });
    builder.addCase(listarConfiguracoes.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setConfig, setPage } = configSlice.actions;

export default configSlice.reducer;

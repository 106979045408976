import axios from "axios";
import { HashModel } from "../models/config/hashModel";
import { ProtocoloModel } from "../models/config/protocoloModel";
import api from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import environment from "../environment";

export const verifarProtocolo = createAsyncThunk("/Resultado/VerificarProtocolo", async (args: ProtocoloModel) => {
  try {
    const data = await api.get("/Resultado/" + args.empresa + "/VerificarProtocolo/" + args.chave + "/" + args.senha);
    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
});

export const verifarHash = createAsyncThunk("/Resultado/VerificarHash", async (args: HashModel) => {
  try {
    const data = await api.get("/Resultado/" + args.empresa + "/VerificarHash/" + args.hash);
    return data.data;
  } catch (error: any) {
    window.location.replace("/erro");
    throw new Error(error.response.data.data);
  }
});

export const listarResultados = createAsyncThunk("/Resultado", async (token: string) => {
  const instance = axios.create({});
  instance.interceptors.request.use(async (config: any) => {
    config.baseURL = environment.baseURL;
    config.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return config;
  });

  try {
    const data = await instance.get("/Resultado/");
    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data.Message);
  }
});

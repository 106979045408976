import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
// import storageSession from "redux-persist/lib/storage/session";
// import { persistReducer } from "redux-persist";

import configReducer from "./config/configSlice";
import resultadoReducer from "./resultado/resultadoSlice";

const reducers = combineReducers({
  config: configReducer,
  resultado: resultadoReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

import "./style/mainTemplate.scss";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import { Layout, Image } from "antd";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import t4iLogo from "../../../util/assets/t4i.svg";
import { useEffect } from "react";

const { Header, Footer, Content } = Layout;

const MainTemplate = ({ children }: any) => {
  const configState = useAppSelector((state) => state.config);
  const resultadoState = useAppSelector((state) => state.resultado);

  return (
    <Layout className="outerLayout">
      <Layout className="innerLayout">
        <Header className="header">
          <Image height={60} preview={false} src={configState.config.logo} />
        </Header>
        <Content className={configState.loading || resultadoState.loading ? "contentLoading" : "content"}>{children}</Content>
        <Footer className="footer">
          <span className="footerText" onClick={() => window.open("https://t4isolutions.com/")}>
            Powered by <Image width={50} height={50} preview={false} src={t4iLogo} />
          </span>
        </Footer>
      </Layout>
      <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable={false} pauseOnHover={false} />
    </Layout>
  );
};

export default MainTemplate;

import { createSlice } from "@reduxjs/toolkit";
import { listarResultados, verifarHash, verifarProtocolo } from "../../services/resultadoService";
import { AtendimentoModel } from "../../models/resultado/atendimentoModel";
import { toast } from "react-toastify";

export interface ResultadoState {
  loading: boolean;
  token: string;
  entity: AtendimentoModel;
  page: boolean;
}

const initialState: ResultadoState = {
  loading: false,
  token: "",
  entity: {} as AtendimentoModel,
  page: false,
};

export const resultadoSlice = createSlice({
  name: "resultado",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    var toaster: any = null;

    //verificar protocolo
    builder.addCase(verifarProtocolo.pending, (state) => {
      state.loading = true;
      toaster = toast.loading("carregando", { position: "bottom-center" });
    });
    builder.addCase(verifarProtocolo.fulfilled, (state, action) => {
      state.token = action.payload;
      state.loading = false;
      toast.update(toaster, {
        render: "Resultado encontrado",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    });
    builder.addCase(verifarProtocolo.rejected, (state, action) => {
      state.loading = false;
      toast.update(toaster, {
        render: action.error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });

    //verificar hash
    builder.addCase(verifarHash.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifarHash.fulfilled, (state, action) => {
      state.token = action.payload;
      state.loading = false;
    });
    builder.addCase(verifarHash.rejected, (state, action) => {
      state.loading = false;
    });

    //listar
    builder.addCase(listarResultados.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listarResultados.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.loading = false;
    });
    builder.addCase(listarResultados.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setPage } = resultadoSlice.actions;

export default resultadoSlice.reducer;

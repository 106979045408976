import "./style/index.scss";
import { Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { setPage } from "../../redux/resultado/resultadoSlice";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { ShareAltOutlined, DownloadOutlined, LeftOutlined, DoubleLeftOutlined, DoubleRightOutlined, PrinterOutlined } from "@ant-design/icons";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import print from "print-js";
import Spinner from "../../components/spinner";
import { ConfigState } from "../../redux/config/configSlice";

const PdfView = (props: any) => {
  //estados globais
  const configState: ConfigState = useAppSelector((state) => state.config);

  //hooks
  const dispatch = useAppDispatch();
  const width = useWindowWidth();

  //estados locais
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [ehMobile, setEhMobile] = useState(false);
  const [ehIos, setEhIos] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    const isMobileDevice = /Mobi/.test(window.navigator.userAgent);
    if (isMobileDevice) {
      setEhMobile(true);
    } else {
      setEhMobile(false);
    }
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("android") > -1) {
      setEhIos(false);
    } else {
      setEhIos(true);
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const sharePDF = async (pdf: string) => {
    try {
      const response = await fetch(`data:application/pdf;base64,${pdf}`);
      const blob = await response.blob();
      const file = new File([blob], "Resultado.pdf", {
        type: "application/pdf",
      });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const shareUrl = reader.result as string;
      if (navigator.share) {
        navigator.share({
          files: [file],
        });
      } else if (navigator.clipboard) {
        navigator.clipboard
          .writeText(shareUrl)
          .then(() => {
            console.log("URL do arquivo copiada para o clipboard");
          })
          .catch((error) => {
            console.error("Erro ao copiar arquivo para o clibboard", error);
          });
      } else {
        console.log("Não é possível compartilhar nesse browser");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPDF = async (pdf: string) => {
    const pdfLink = "data:application/pdf;base64," + pdf;
    const element = document.createElement("a");
    const fileName = "Resultado.pdf";
    element.href = pdfLink;
    element.download = fileName;
    element.click();
  };

  const printPDF = (pdf: string) => {
    const byteCharacters = atob(pdf);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    print(pdfUrl);
  };

  const voltar = () => {
    dispatch(setPage(false));
  };

  return (
    <>
      <div className="actionButtons">
        <div onClick={voltar} className="voltar">
          <LeftOutlined />
        </div>
        <div className="pdfButtons">
          {ehMobile ? (
            <>
              <Tooltip title="Compartilhar">
                <Button
                  icon={<ShareAltOutlined />}
                  onClick={() => sharePDF(props.pdf)}
                  style={{
                    backgroundColor: configState.config.corPrimaria,
                    borderRadius: "5px",
                    color: "#ffffff",
                  }}
                />
              </Tooltip>
              {ehIos ? (
                <>
                  <Tooltip title="Imprimir">
                    <Button
                      icon={<PrinterOutlined />}
                      onClick={() => printPDF(props.pdf)}
                      style={{
                        backgroundColor: "#404040",
                        borderRadius: "5px",
                        marginRight: "9px",
                        color: "#ffffff",
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Baixar">
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={() => downloadPDF(props.pdf)}
                      style={{
                        backgroundColor: configState.config.corSecundaria,
                        borderRadius: "5px",
                        marginRight: "9px",
                        color: "#ffffff",
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              <Tooltip title="Compartilhar">
                <Button
                  icon={<ShareAltOutlined />}
                  onClick={() => sharePDF(props.pdf)}
                  style={{
                    backgroundColor: configState.config.corPrimaria,
                    borderRadius: "5px",
                    color: "#ffffff",
                  }}
                />
              </Tooltip>
              <Tooltip title="Baixar">
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => downloadPDF(props.pdf)}
                  style={{
                    backgroundColor: configState.config.corSecundaria,
                    borderRadius: "5px",
                    color: "#ffffff",
                  }}
                />
              </Tooltip>
              <Tooltip title="Imprimir">
                <Button
                  icon={<PrinterOutlined />}
                  onClick={() => printPDF(props.pdf)}
                  style={{
                    backgroundColor: "#404040",
                    borderRadius: "5px",
                    marginRight: "9px",
                    color: "#ffffff",
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <Document file={"data:application/pdf;base64," + props.pdf} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess} loading={<Spinner />} className="pdfDocument">
        <Page width={width ? Math.min(width * 0.97, 800) : 800} pageNumber={pageNumber} />
      </Document>
      <div className="pagination">
        <div className="page">
          <p>
            Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
          </p>
        </div>
        {numPages === 1 ? (
          <></>
        ) : (
          <>
            <div className="pageButtons">
              <Tooltip title="Anterior">
                <Button icon={<DoubleLeftOutlined style={{ color: "#404040" }} />} disabled={pageNumber <= 1} onClick={previousPage} />
              </Tooltip>
              <Tooltip title="Próximo">
                <Button icon={<DoubleRightOutlined />} disabled={pageNumber >= numPages} onClick={nextPage} />
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PdfView;

import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../util/hooks";
import Acessar from "./acessar";
import resultados from "./resultados";
import { useEffect } from "react";
import { listarConfiguracoes } from "../services/configService";
import { listarResultados, verifarHash } from "../services/resultadoService";
import { setPage } from "../redux/config/configSlice";
import Spinner from "../components/spinner";

const PageController = () => {
  //estados globais
  const configState = useAppSelector((state) => state.config);
  const resultadoState = useAppSelector((state) => state.resultado);

  //hooks
  const dispatch = useAppDispatch();
  const location = useLocation();

  //estados locais
  const url = location.pathname;
  const urlList = url.split("/");

  const pages: any = {
    acessar: Acessar,
    resultados: resultados,
  };

  const fetchData = async () => {
    var hashArgs = {
      empresa: urlList[1],
      hash: urlList[2],
    };
    const token = await dispatch(verifarHash(hashArgs));
    await dispatch(listarResultados(token.payload));
    dispatch(setPage("resultados"));
  };

  useEffect(() => {
    dispatch(listarConfiguracoes(urlList[1]));
    if (urlList.length === 3 && urlList[2] !== "") {
      fetchData();
    }
  }, []);

  const Page = pages[configState.page];

  return <>{configState.loading || resultadoState.loading ? <Spinner /> : <Page />}</>;
};

export default PageController;

import "./style/index.scss";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Image } from "antd";
import { useState } from "react";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { setPage } from "../../redux/config/configSlice";
import ReCAPTCHA from "react-google-recaptcha";
import banner from "./../../util/assets/bannerApp.png";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { listarResultados, verifarProtocolo } from "../../services/resultadoService";
import { toast } from "react-toastify";

const Acessar = () => {
  //estados globais
  const configState = useAppSelector((state) => state.config);

  //hooks
  const dispatch = useAppDispatch();
  let location = useLocation();
  const width = useWindowWidth();

  //estados locais
  const [form] = Form.useForm();
  const url = location.pathname;
  const urlList = url.split("/");
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);

  const handleCaptchaChange = (response: string | null) => {
    setCaptchaResponse(response);
  };

  const submit = async (value: any) => {
    if (captchaResponse) {
      var protocoloArgs = {
        empresa: urlList[1],
        chave: value.chave,
        senha: value.senha,
      };
      const token = await dispatch(verifarProtocolo(protocoloArgs));
      if (token.type.includes("fulfilled")) {
        await dispatch(listarResultados(token.payload));
        dispatch(setPage("resultados"));
      }
    } else {
      toast.error("Resolva o captcha para poder avançar", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Image width={width ? Math.min(width * 1, 800) : 800} preview={false} src={banner} />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24} className="acessarBox">
          <Card key="1">
            <Form form={form} layout="vertical" onFinish={submit}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Form.Item
                    label={configState.config.nomeCampoCodigo}
                    name="chave"
                    rules={[
                      {
                        required: true,
                        message: configState.config.nomeCampoCodigo.includes("Chave") ? "Digite a " + configState.config.nomeCampoCodigo.toLowerCase() : "Digite o " + configState.config.nomeCampoCodigo.toLowerCase(),
                      },
                    ]}
                  >
                    <Input type="text" placeholder={configState.config.nomeCampoCodigo.includes("Chave") ? "Digite a " + configState.config.nomeCampoCodigo.toLowerCase() : "Digite o " + configState.config.nomeCampoCodigo.toLowerCase()} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Form.Item
                    label={configState.config.nomeCampoSenha}
                    name="senha"
                    rules={[
                      {
                        required: true,
                        message: configState.config.nomeCampoSenha.includes("Senha") ? "Digite a " + configState.config.nomeCampoSenha.toLowerCase() : "Digite o " + configState.config.nomeCampoSenha.toLowerCase(),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={configState.config.nomeCampoSenha.includes("Senha") ? "Digite a " + configState.config.nomeCampoSenha.toLowerCase() : "Digite o " + configState.config.nomeCampoSenha.toLowerCase()}
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="g-recaptcha">
                <ReCAPTCHA
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sitekey="6Lc_ZNolAAAAAK-1ZFX1CcoP5SttTrTu8mP7sDc9"
                  onChange={handleCaptchaChange}
                />
              </div>
              <Divider></Divider>
              <Row gutter={[24, 24]}>
                <Col span={24} className="acessarButtonCol">
                  <Button
                    htmlType="submit"
                    className="acessarButton"
                    style={{
                      backgroundColor: configState.config.corPrimaria,
                      color: "#ffffff",
                    }}
                  >
                    ACESSAR
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default Acessar;

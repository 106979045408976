import { Route, Routes, BrowserRouter } from "react-router-dom";
import MainTemplate from "./components/templates/main/mainTemplate";
import PageController from "./views/pageController";

const RouteComponent = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/erro" element={<h1>Desculpe-nos, mas ocorreu um problema ao acessar o resultado do seu exame. Por favor, tente novamente mais tarde.</h1>} />
      <Route
        path="/:empresa"
        element={
          <MainTemplate>
            <PageController />
          </MainTemplate>
        }
      />
      <Route
        path="/:empresa/"
        element={
          <MainTemplate>
            <PageController />
          </MainTemplate>
        }
      />
      <Route
        path="/:empresa/:hash"
        element={
          <MainTemplate>
            <PageController />
          </MainTemplate>
        }
      />
      <Route path="*" element={<h1>A página que você tentou acessar não existe.</h1>} />
    </Routes>
  </BrowserRouter>
);

export default RouteComponent;
